import cn from "../lib/class-names"
import IChildrenProps from "../interfaces/children-props"
import IMouseProps from "../interfaces/mouse-props"
import * as React from "react"

interface IProps extends IChildrenProps, IMouseProps {
  center?: boolean
  tabIndex?: number
  tag?: string
}

function BaseCol({
  className = "",
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tabIndex,
  children,
}: IProps) {
  return (
    <div
      className={cn("flex flex-col", className)}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={tabIndex}
    >
      {children}
    </div>
  )
}

export default BaseCol
