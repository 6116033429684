import { ITitleLayoutProps } from "../interfaces/title-layout-props"
import ContentLayout from "./content-layout"
import * as React from "react"

import SeventyLayout from "./seventy-layout"

interface IProps extends ITitleLayoutProps {
  sideClassName?: string
  footerClassName?: string
}

function SideLayout({
  title,
  showTitle = true,
  description,
  tab,
  isIndexed,
  crumbs,
  supertitle,
  sideClassName,
  className,
  footerClassName,
  location,
  children,
}: IProps) {
  return (
    <ContentLayout
      title={title}
      crumbs={crumbs}
      supertitle={supertitle}
      showTitle={showTitle}
      description={description}
      tab={tab}
      isIndexed={isIndexed}
      location={location}
      className={className}
      footerClassName={footerClassName}
    >
      <></>
      <SeventyLayout className={sideClassName}>{children}</SeventyLayout>
    </ContentLayout>
  )
}

export default SideLayout
