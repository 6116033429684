import { faChevronRight, faHouse } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import cn from "../lib/class-names"
import { toUpperCase } from "../lib/text"
import IClassProps from "../interfaces/class-props"
import ICrumb from "../interfaces/crumb"
import ICrumbProps from "../interfaces/crumb-props"
import BlackLink from "./link/black-link"
import ToBlueLink from "./link/to-blue-link"
import WhiteLink from "./link/white-link"

const EXCLUDE = ["Tag", "Section", "Page"]

function getCrumbLink(crumb: ICrumb, mode = "light") {
  if (mode === "dark") {
    return (
      <WhiteLink
        href={crumb[1]}
        underline={true}
        ariaLabel={`Visit ${crumb[0]}`}
      >
        {crumb[0]}
      </WhiteLink>
    )
  } else {
    return (
      <ToBlueLink
        href={crumb[1]}
        underline={true}
        ariaLabel={`Visit ${crumb[0]}`}
      >
        {crumb[0]}
      </ToBlueLink>
    )
  }
}

function createCrumbs(url: string): ICrumb[] {
  const segments = url.split("/").filter(s => s.length > 0)

  const crumbs: ICrumb[] = []

  for (let i = 0; i < segments.length; ++i) {
    const name = toUpperCase(
      segments[i].replace(/^\d{4}-\d{2}-\d{2}-/, "")
    ).replace("Faq", "FAQ")
    const path = `/${segments.slice(0, i + 1).join("/")}`
    if (!EXCLUDE.includes(name) && name.search(/^\d+$/) === -1) {
      crumbs.push([name, path])
    }
  }

  return crumbs
}

interface BreadcrumbProps extends IClassProps, ICrumbProps {
  url: string
  mode?: string
}

function Breadcrumb({
  crumbs,
  url,
  mode = "light",
  className = "",
}: BreadcrumbProps) {
  if (crumbs) {
    if (crumbs.length === 0) {
      crumbs = createCrumbs(url)
    }
  } else {
    crumbs = []
  }

  const ret: any[] = []

  ret.push(
    <li key="home">
      <BlackLink href="/" ariaLabel="Home">
        <FontAwesomeIcon icon={faHouse} className="w-3" />
      </BlackLink>
    </li>
  )

  for (let i = 0; i < crumbs.length; ++i) {
    const crumb = crumbs[i]

    ret.push(
      <li key={`divider-${i}`}>
        <FontAwesomeIcon icon={faChevronRight} className="w-3" />
      </li>
    )

    ret.push(
      <li key={`crumb-${ret.length}`}>
        {i < crumbs.length - 1 ? getCrumbLink(crumb, mode) : crumb[0]}
      </li>
    )
  }

  return (
    <ul
      className={cn(
        "flex flex-row flex-nowrap items-center gap-x-2 text-xs",
        [mode === "dark", "text-gray-50"],
        className
      )}
    >
      {ret}
    </ul>
  )
}

export default Breadcrumb
