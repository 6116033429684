import BaseCol from "../components/base-col"
import BlueLink from "../components/link/blue-link"
import { SITE_NAME, UPDATED, VERSION, YEAR } from "../constants"
import SideLayout from "../layouts/side-layout"
import * as React from "react"
import IDataPageProps from "../interfaces/data-page-props"
import Seo from "../layouts/seo"
import { ROUNDED_BUTTON_CLASSES } from "../components/link/rounded-button-link"
import cn from "../lib/class-names"

const CLS = cn(
  ROUNDED_BUTTON_CLASSES,
  "block px-5 py-2 bg-sky-100 font-semibold"
)

export default function Page({ data, location }: IDataPageProps) {
  return (
    <SideLayout title="Help" showTitle={false} location={location}>
      <>
        <BaseCol
          className={cn(ROUNDED_BUTTON_CLASSES, "gap-y-4 bg-apple-gray p-8")}
        >
          <p>
            <img
              src="/assets/svg/logo.svg"
              alt="Dalla Favera Lab logo"
              width="236"
              height="30"
              className="w-56"
            />
          </p>

          <div>
            <p>{`Version ${VERSION}`}</p>
            <p>{`Updated ${UPDATED}`}</p>
          </div>
        </BaseCol>

        <div className={cn(ROUNDED_BUTTON_CLASSES, "mt-8 bg-apple-gray p-8")}>
          <p>{SITE_NAME}</p>
          <p>
            Copyright &copy; {YEAR} {SITE_NAME}. All rights reserved.
          </p>
          <p>
            Developed by{" "}
            <BlueLink
              href="https://www.antonyholmes.dev"
              ariaLabel="Email Antony Holmes"
            >
              Antony Holmes
            </BlueLink>
            .
          </p>
        </div>

        <div className="mt-16">
          <p>
            This website is made possible by open source software and other
            services:
          </p>

          <ul className="mt-4 flex flex-row flex-wrap gap-4">
            <li className={CLS}>
              <BlueLink href="https://www.gatsbyjs.com" ariaLabel="View tool">
                Gatsby
              </BlueLink>
            </li>
            <li className={CLS}>
              <BlueLink href="https://reactjs.org" ariaLabel="View tool">
                React
              </BlueLink>
            </li>
            <li className={CLS}>
              <BlueLink href="https://tailwindcss.com/" ariaLabel="View tool">
                Tailwind
              </BlueLink>
            </li>
            {/* <li className={CLS}>
              <BlueLink href="https://www.w3.org/html" ariaLabel="View tool">
                HTML5
              </BlueLink>
            </li> */}
            <li className={CLS}>
              <BlueLink href="https://www.npmjs.com" ariaLabel="View tool">
                NPM
              </BlueLink>
            </li>
            <li className={CLS}>
              <BlueLink href="https://nodejs.org/" ariaLabel="View tool">
                Node.js
              </BlueLink>
            </li>
            <li className={CLS}>
              <BlueLink href="https://github.com" ariaLabel="View tool">
                GitHub
              </BlueLink>
            </li>
            <li className={CLS}>
              <BlueLink
                href="https://code.visualstudio.com"
                ariaLabel="View tool"
              >
                Visual Studio Code
              </BlueLink>
            </li>

            {/* <li className={CLS}>
              <BlueLink href="https://www.cloudflare.com/">Cloudflare</BlueLink>
            </li> */}
          </ul>
        </div>
      </>
      <></>
    </SideLayout>
  )
}

export const Head = () => <Seo title="Help" />
